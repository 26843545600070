import { Component, OnInit } from '@angular/core';
import { InstituteService } from '../../services/institute.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  public insititutes = [
    { name: 'digitalqq', logo: 'http://logo.png', url: '/digital' },
  ];
  constructor(private instituteService: InstituteService) {
    this.insititutes = this.instituteService.getInstitutes();
    console.log(this.insititutes);
    console.log('sfsdf');
  }

  ngOnInit(): void {}
}
