import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DigitalComponent } from './components/institutes/digital/digital.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';

const routes: Routes = [
  {
    path: 'institute/lakeland-college',
    component: DigitalComponent,
  },
  {
    path: '',
    component: LandingPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
