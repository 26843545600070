import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InstituteService {
  public insititutes = [
    {
      name: 'Lakeland college',
      logo: 'LAKELAND_LOGO_u0mwsf-1.png',
      url: 'lakeland-college',
    },
  ];
  constructor() {}
  public getInstitutes() {
    return this.insititutes;
  }
}
