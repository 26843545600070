<div class="landingDc-pg">
  <div class="container-1">
    <div class="ldcbox-row">
      <div class="ldc-box">
        <div class="ldc-box-header">
          <div class="ldc-logo">
            <img
              src="/assets/images/Landing-DC/Lakeland_CMYK_Horizontal_iwjzyq.png"
              alt="logo"
            />
          </div>
          <div class="ldc-text1">Career Development</div>
        </div>
        <p>
          Welcome, please select one of the following options to continue and
          access the platform
        </p>
        <a href="https://peersview.com/privacy-policy" class="link">
          Please click here to view our Privacy Policy</a
        >
        <div class="btn-wrap">
          <a href="https://peersview.com/sign-up" class="btn-secondary1-full"
            >Students & Alumni
            <img
              width="20"
              src="/assets/images/Landing-DC/ico-right-circle.png"
              alt="ico"
          /></a>
        </div>
        <div class="btn-wrap">
          <a href="https://dashboard.peersview.com" class="btn-secondary1-full"
            >Staffs
            <img
              width="20"
              src="/assets/images/Landing-DC/ico-right-circle.png"
              alt="ico"
          /></a>
        </div>
      </div>
    </div>
  </div>
</div>
