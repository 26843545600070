<div class="container">
  <div class="row">
    <div class="col-sm-12">
      <h1 class="h1 bold-text u-textsize-40px custom">
        Connecting students and alumni for networking and mentorship
      </h1>
      <div class="text-center">
        Find your school to start having virtual coffee chats.
      </div>
      <div class="col-12 mt-4">
        <div class="row" *ngIf="insititutes?.length">
          <div class="col-4" *ngFor="let insititute of insititutes">
            <a [routerLink]="['/institute/', insititute.url]">
              <img
                width="20"
                src="{{ '/assets/images/Landing-DC/' + insititute.logo }}"
                alt="ico"
              />
              {{ insititute.name }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
